body .header .subheader {
  font-weight: 700;
}

body .header .subheader span {
  color: #ffa9ab;
}

body .block > .content .split {
  display: flex;
}

body .block > .content .split > * {
  flex-grow: 1;
  width: 50%;
}

body .block > .content .split > .vCenter {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

body .block > .content .split > .vCenter picture {
  justify-content: center;
  display: flex;
}

body .block > .content .split > .vCenter picture > img {
  width: 100%;
  max-width: max-content;
}

body .block > .content .product {
  color: #fff;
  background: #536ced;
  border-radius: 8px;
  gap: 40px;
  padding: 40px;
}

body .block > .content .product .left img, body .block > .content .product .right img {
  width: 100%;
  max-width: max-content;
}

body .block > .content .product > div {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

body .block > .content .product > div > div {
  margin-top: 16px;
}

body .block > .content .product h2 {
  text-align: left;
  margin: 0 0 16px;
}

body .block > .content .product h2:last-of-type {
  font-family: CirceBold;
  font-weight: initial;
  -webkit-font-smoothing: antialiased;
  font-size: 40px;
  line-height: 48px;
}

body .block > .content .product h2 > img {
  vertical-align: sub;
  height: 48px;
}

body .block > .content .product p {
  margin: 0 0 16px;
}

body .block > .content .product p.bold {
  font-family: CirceBold;
  font-weight: initial;
  -webkit-font-smoothing: antialiased;
  font-size: 20px;
  line-height: 32px;
}

body .block > .content .product p > img {
  vertical-align: bottom;
}

body .block > .content .product.first, body .block > .content .product.second {
  margin-bottom: 24px;
}

body .block > .content .product.second .more, body .block > .content .product.third .more {
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 16px;
  display: flex;
  position: relative;
}

body .block > .content .product.second .more .labels, body .block > .content .product.third .more .labels {
  gap: 16px;
  margin-top: 32px;
  display: flex;
}

body .block > .content .product.second .more .labels .hint, body .block > .content .product.third .more .labels .hint {
  text-align: center;
}

body .block > .content .product.second .more .labels .hint .text, body .block > .content .product.third .more .labels .hint .text {
  left: auto;
  right: 0;
}

body .block > .content .product.second .more .labels .hint .desc, body .block > .content .product.third .more .labels .hint .desc {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

body .block > .content h2 {
  margin-bottom: 12px;
}

body .block > .content p {
  margin-bottom: 24px;
}

body .block.brands .content {
  flex-direction: column;
  align-items: center;
  gap: 40px;
  display: flex;
}

body .block.brands .content h2 {
  margin-bottom: 0;
}

body .block.brands .content .items {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 8px;
  width: 100%;
  display: grid;
}

body .block.brands .content .items div {
  background: #eaf1ff;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  padding: 24px 0;
  display: flex;
}

body .block.customerSuccess a {
  color: #536ced;
}

body .block.customerSuccess .steps {
  font-family: CirceBold;
  font-weight: initial;
  -webkit-font-smoothing: antialiased;
  justify-content: center;
  gap: 80px;
  margin: 40px auto;
  font-size: 16px;
  line-height: 24px;
  display: flex;
}

body .block.customerSuccess .steps > div {
  cursor: pointer;
}

body .block.customerSuccess .steps > div span:first-child {
  text-align: center;
  border: 1px solid #536ced;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  margin-right: 8px;
  line-height: 32px;
  display: inline-block;
}

body .block.customerSuccess .steps > div.active > span:first-child {
  color: #fff;
  background-color: #536ced;
}

body .block.customerSuccess .split {
  gap: 40px;
  display: none;
}

body .block.customerSuccess .split.active {
  display: flex;
}

body .block.customerSuccess > div {
  margin: 0 auto;
}

body .block.customerSuccess p {
  margin: 0;
}

body .block.stories {
  margin-bottom: 80px;
}

body .block.stories .steps {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 24px 74px;
  margin: 40px 0 24px;
  display: flex;
}

body .block.stories .steps svg {
  filter: grayscale();
  opacity: .5;
}

body .block.stories .steps.mobile {
  display: none;
}

body .block.stories .steps [data-slider-step] {
  cursor: pointer;
}

body .block.stories .steps [data-slider-step].active svg {
  filter: grayscale(0%);
  opacity: 1;
}

body .block.stories .steps img {
  filter: invert();
}

body .block.stories div.center {
  margin-bottom: 32px;
}

body .block.stories [data-slider-item] {
  display: none;
}

body .block.stories [data-slider-item] .author-wrapper {
  align-items: center;
}

body .block.stories [data-slider-item] .author-wrapper div {
  display: flex;
}

body .block.stories [data-slider-item] .author-wrapper img {
  width: 48px;
  height: 48px;
}

body .block.stories [data-slider-item].active {
  display: block;
}

body .block.stories a {
  text-align: center;
  color: #536ced;
  border: 1px solid #536ced;
  border-radius: 8px;
  align-items: center;
  height: 32px;
  margin-top: 32px;
  padding: 4px 12px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  display: flex;
}

body .block.try .content {
  text-align: center;
  color: #fff;
  background-color: #536ced;
  border-radius: 8px;
  padding: 80px 40px;
}

body .block.try .content p {
  margin-bottom: 32px;
}

body .block .center {
  text-align: center;
}

body .block .slider-wrapper.mobile {
  width: calc(100% + 32px);
  position: relative;
  left: -16px;
  overflow: hidden;
}

body .block .slider-wrapper.mobile .slider-track {
  gap: 8px;
  display: flex;
}

body .block .slider-wrapper.mobile .slider-track:not(.moving) {
  transition: transform .5s;
}

body .block .slider-wrapper.mobile .slider-track > [data-slider-item] {
  scroll-snap-align: center;
  background: #eaf1ff;
  border-radius: 8px;
  flex-direction: column;
  gap: 32px;
  padding: 32px 16px;
  display: flex;
}

body .block .slider-wrapper.mobile .slider-track > [data-slider-item] > div {
  width: 100%;
}

@media only screen and (width <= 1280px) {
  body .block.products .content .product.split {
    flex-direction: column-reverse;
    align-items: center;
  }

  body .block.products .content .product.split h2, body .block.products .content .product.split p {
    text-align: left;
  }

  body .block.products .content .product.split.first .right, body .block.products .content .product.split.third .right {
    align-items: center;
  }

  body .block.products .content .product.split.second {
    flex-direction: column;
  }

  body .block.products .content .product.split.second .left {
    align-items: center;
  }

  body .block.products .content .product.split.second .more, body .block.products .content .product.split.third .more {
    display: block !important;
  }

  body .block.products .content .product.split.second .more > div:first-child, body .block.products .content .product.split.third .more > div:first-child {
    margin-bottom: 32px;
  }

  body .block.products .content .product.split > * {
    width: 100%;
  }
}

@media only screen and (width <= 768px) {
  body .block .center {
    text-align: inherit;
  }

  body .block.products .content {
    margin-bottom: 0;
  }

  body .block.products .content h1 {
    margin-bottom: 12px;
  }

  body .block.products .content .product:last-child {
    margin-top: 0;
  }

  body .block.products .content .product.first div:last-child {
    margin-top: 16px;
  }

  body .block.products .content .product h2 {
    font-family: CirceBold;
    font-weight: initial;
    -webkit-font-smoothing: antialiased;
    font-size: 32px;
    line-height: 40px;
  }

  body .block.products .content .product h2 > img {
    vertical-align: text-top;
    height: 32px;
  }

  body .block.customerSuccess .steps {
    justify-content: flex-start;
    gap: 30px;
  }

  body .block.try .content {
    padding: 32px 16px;
  }

  body .block.brands .content {
    gap: 32px;
  }

  body .block.brands .content .items {
    grid-template-columns: 1fr 1fr;
  }

  body .block.brands .content .button {
    text-align: center;
    width: 256px;
  }
}

@media only screen and (width <= 650px) {
  body .block.products .product {
    padding: 32px 16px;
  }

  body .block.products .product.second .more .labels .hint .text, body .block.products .product.third .more .labels .hint .text {
    left: auto;
    right: -46px;
  }

  body .block.products .product.second .more .labels .hint:last-of-type .text, body .block.products .product.third .more .labels .hint:last-of-type .text {
    left: auto;
    right: 11px;
  }
}

@media only screen and (width <= 480px) {
  body .block.products .button {
    text-align: center;
    width: 100%;
  }

  body .block.products .center {
    text-align: inherit;
  }

  body .block.products .second .more .labels, body .block.products .third .more .labels {
    justify-content: center;
  }

  body .block.customerSuccess .steps {
    justify-content: space-evenly;
    gap: initial;
  }

  body .block.customerSuccess .steps > div {
    flex-direction: column;
    align-items: center;
    gap: 8px;
    display: flex;
  }

  body .block.try .content {
    text-align: left;
  }

  body .block.try .content .button {
    text-align: center;
    width: 100%;
  }
}
/*# sourceMappingURL=index.6ec49cb1.css.map */
