@use "../../public/styles/variables.scss";

@mixin slider {
  .slider-wrapper {
    &.mobile {
      position: relative;
      width: calc(100% + 32px);
      left: -16px;
      overflow: hidden;

      .slider-track {
        display: flex;
        gap: 8px;

        &:not(.moving) {
          transition: transform 500ms ease 0s;
        }
    
        > [data-slider-item] {
          padding: 32px 16px;
          scroll-snap-align: center;
          display: flex;
          flex-direction: column;
          gap: 32px;
          background: variables.$neutral_03;
          border-radius: 8px;


          > div {
            width: 100%; 
          }
        }
      }
    }
  }
}
