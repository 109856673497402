@use './variables.scss';
@use '../../partials/styles/slider.scss' as *;

body {
  .header .subheader {
    font-weight: 700;

    span {
      color: variables.$accent_03;
    }
  }

  .block {
    >.content {
      .split {
        display: flex;

        >* {
          width: 50%;
          flex-grow: 1;

          &.vCenter {
            display: flex;
            flex-direction: column;
            justify-content: center;

            picture {
              display: flex;
              justify-content: center;

              > img {
                width: 100%;
                max-width: max-content;
              }
            }
          }
        }
      }

      .product {
        background: variables.$neutral_01;
        border-radius: 8px;
        color: variables.$ui_01;
        padding: 40px;
        gap: 40px;

        .left img,
        .right img {
          width: 100%;
          max-width: max-content;
        }

        > div {
          display: flex;
          flex-direction: column;
          justify-content: center;

          > div {
            margin-top: 16px;
          }
        }

        h2 {
          margin: 0 0 16px 0;
          text-align: left;

          &:last-of-type {
            @include variables.H2;
          }

          > img {
            vertical-align: sub;
            height: 48px;
          }
        }

        p {
          margin: 0 0 16px 0;

          &.bold {
            @include variables.P2Bold;
          }

          > img {
            vertical-align: bottom;
          }
        }

        &.first, &.second {
          margin-bottom: 24px;
        }

        &.second, &.third {
          .more {
            display: flex;
            justify-content: space-between;
            margin-top: 16px;
            position: relative;
            flex-direction: column;
            align-items: baseline;

            .labels {
              display: flex;
              gap: 16px;
              margin-top: 32px;

              .hint {
                text-align: center;

                .text {
                  right: 0px;
                  left: auto;
                }

                .desc {
                  @include variables.P4;
                }
              }
            }
          }
        }
      }

      h2 {
        margin-bottom: 12px;
      }

      p {
        margin-bottom: 24px;
      }
    }

    &.brands .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 40px;

      h2 {
        margin-bottom: 0px;
      }

      .items {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        gap: 8px;
        width: 100%;

        div {
          display: flex;
          justify-content: center;
          align-items: center;
          background: variables.$neutral_03;
          border-radius: 8px;
          padding: 24px 0;
        }
      }
    }

    &.customerSuccess {
      a {
        color: variables.$link;
      }

      .steps {
        display: flex;
        justify-content: center;
        margin: 40px auto;
        gap: 80px;
        @include variables.P3Bold;

        > div {
          cursor: pointer;

          span:first-child {
            border: 1px solid variables.$neutral_01;
            display: inline-block;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            line-height: 32px;
            margin-right: 8px;
            text-align: center;
          }

          &.active > span:first-child {
            background-color: variables.$neutral_01;
            color: variables.$ui_01;
          }
        }
      }

      .split {
        display: none;
        gap: 40px;

        &.active {
          display: flex;
        }
      }

      >div {
        margin: 0 auto;
      }

      p {
        margin: 0;
      }
    }

    &.stories {
      margin-bottom: 80px;

      .steps {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        column-gap: 74px;
        row-gap: 24px;
        margin: 40px 0 24px;

        svg {
          filter: grayscale(100%);
          opacity: 0.5;
        }

        &.mobile {
          display: none;
        }

        [data-slider-step] {
          cursor: pointer;

          &.active svg {
            filter: grayscale(0%);
            opacity: 1;
          }
        }

        img {
          filter: invert(1);
        }
      }

      div.center {
        margin-bottom: 32px;
      }

      [data-slider-item] {
        display: none;

        .author-wrapper {
          align-items: center;

          div {
            display: flex;
          }

          img {
            width: 48px;
            height: 48px;
          }
        }

        &.active {
          display: block;
        }
      }

      a {
        margin-top: 32px;
        padding: 4px 12px;
        height: 32px;
        border: 1px solid variables.$link;
        border-radius: 8px;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        text-align: center;
        color: variables.$link;
      }
    }

    &.try .content {
      background-color: variables.$neutral_01;
      background-color: #536ced;
      padding: 80px 40px;
      text-align: center;
      color: variables.$ui_01;
      border-radius: 8px;

      p {
        margin-bottom: 32px;
      }
    }

    .center {
      text-align: center;
    }

    @include slider;
  }
}

@media only screen and (max-width: 1280px) {
  body .block.products .content .product.split {
    flex-direction: column-reverse;
    align-items: center;

    h2, p {
      text-align: left;
    }

    &.first .right, &.third .right {
      align-items: center;
    }

    &.second {
      flex-direction: column;

      .left {
        align-items: center;
      }
    }

    &.second, &.third {
      .more {
        display: block !important;

        > div:first-child {
          margin-bottom: 32px;
        }
      }
    }

    >* {
      width: 100%;
    }
  }

}

@media only screen and (max-width: 768px) {
  body {
    .block .center {
      text-align: inherit;
    }

    .block.products {
      .content {
        margin-bottom: 0;

        h1 {
          margin-bottom: 12px;
        }

        .product {
          &:last-child {
            margin-top: 0px;
          }

          &.first div:last-child {
            margin-top: 16px;
          }

          h2 {  
            @include variables.H2Mobile;
  
            > img {
              vertical-align: text-top;
              height: 32px;
            }
          }
        }
      }
    }

    .block.customerSuccess .steps {
      justify-content: flex-start;
      gap: 30px;
    }

    .block.try .content {
      padding: 32px 16px;
    }

    .block.brands .content {
      gap: 32px;

      .items {
        grid-template-columns: 1fr 1fr;
      }

      .button {
        width: 256px;
        text-align: center;
      }
    }
  }
}

@media only screen and (max-width: 650px) {
  body .block {
    &.products .product {
      padding: 32px 16px;

      &.second, &.third {
        .more .labels .hint .text {
          right: -46px;
          left: auto;
        }

        .more .labels .hint:last-of-type .text {
          right: 11px;
          left: auto;
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  body {
    .block.products {
      .button {
        width: 100%;
        text-align: center;
      }
      
      .center {
        text-align: inherit;
      }

      .second .more .labels,
      .third .more .labels {
        justify-content: center;
      }
    }  

    .block.customerSuccess .steps {
      justify-content: space-evenly;
      gap: initial;

      > div {
        display: flex;
        gap: 8px;
        align-items: center;
        flex-direction: column;
      }
    }

    .block.try .content{
      text-align: left;

      .button {
        width: 100%;
        text-align: center;
      }
    }
  }
}